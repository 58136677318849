import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import DataTable from '../../juristec-ui/core/DataTable';
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import Tooltip from '../../juristec-ui/core/Tooltip';
import Badge from '../../juristec-ui/core/Badge';
import DataCards from '../../juristec-ui/core/DataCards';
import IconButton from '../../juristec-ui/core/IconButton';
import TablePagination from '../../juristec-ui/core/TablePagination';

import {
  UserConfig,
  History,
  Dashboard,
  UserAtom,
  Users,
  Question,
  ProfileUser,
  Trash,
  UserReactivate,
} from '../../juristec-ui/icons';

import useDeviceType from '../../juristec-ui/hooks/useDeviceType';

import {
  UserInfoText,
  Options,
  Table,
  UsersTableOverFlow,
  BadgeContent,
  IconWrapper,
} from './styled/UsersTable.styled';
import Unlock from '../../juristec-ui/icons/Unlock';

/**
 * A component that displays the users info and options
 */
const UsersTable = ({
  users,
  getUsers,
  getUserInfo,
  getUserHistory,
  getUserDashboards,
  filterText,
  handleDelete,
  handleReactivateUser,
  handleGetResetPwdLink,
  userIsAdmin,
}) => {
  const device = useDeviceType();
  const theme = useTheme();

  const [data, setData] = useState(users);
  const [page, setPage] = useState(0);

  const DataListingComponent = device === 'phone' ? DataCards : DataTable;

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filterText]);

  useEffect(() => {
    const value = filterText.toLowerCase();
    setData(users.filter((user) => (
      user?.displayName && user?.displayName.toLowerCase().includes(value))
      || (user?.email && user?.email.toLowerCase().includes(value))));
  }, [users, filterText]);

  const getUsersFromSameCompany = (userCompanyId) => users.filter(
    (user) => user?.company?.id === userCompanyId,
  );

  const UserOptions = ({ user }) => {
    const getInfo = (e) => {
      e.stopPropagation();
      getUserInfo(user);
    };
    const getHistory = (e) => {
      e.stopPropagation();
      getUserHistory(user);
    };
    const getDashboards = (e) => {
      const sameCompanyUsers = getUsersFromSameCompany(user?.company?.id);
      e.stopPropagation();
      getUserDashboards(user, sameCompanyUsers);
    };

    const deleteUser = (e) => {
      e.stopPropagation();
      handleDelete(user);
    };

    const reactivateUser = (e) => {
      e.stopPropagation();
      const { handler, modalHandler } = handleReactivateUser(user);

      const aux = (d) => {
        setData((p) => p.map((u) => (u.uid === user.uid ? { ...u, removed: d } : u)));
      };

      const handlerWrapper = () => handler(aux);
      modalHandler(handlerWrapper);
    };

    const getResetPwdLink = (e) => {
      e.stopPropagation();
      handleGetResetPwdLink(user);
    };

    return (
      <Options>
        <Tooltip text="Informações">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getInfo}>
            <ProfileUser />
          </IconButton>
        </Tooltip>
        <Tooltip text="Acessos">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getHistory}>
            <History />
          </IconButton>
        </Tooltip>
        <Tooltip text="Dashboards">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getDashboards}>
            <Dashboard />
          </IconButton>
        </Tooltip>
        { userIsAdmin && (
          user?.removed ? (
            <Tooltip text="Reativar usuário">
              <IconButton variant="pattern" shape="rounded" color="primary" onClick={reactivateUser}>
                <UserReactivate />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip text="Gerar link de reset de senha">
                <IconButton variant="pattern" shape="rounded" color="primary" onClick={getResetPwdLink}>
                  <Unlock />
                </IconButton>
              </Tooltip>
              <Tooltip text="Apagar usuário">
                <IconButton variant="pattern" shape="rounded" color="primary" onClick={deleteUser}>
                  <Trash />
                </IconButton>
              </Tooltip>
            </>
          )
        )}
      </Options>
    );
  };

  UserOptions.propTypes = {
    user: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.string,
      }),
      user_id: PropTypes.string,
      removed: PropTypes.bool,
      uid: PropTypes.string,
    }).isRequired,
  };

  /* eslint-disable react/destructuring-assignment */
  const UserInfo = (user) => (
    <UserInfoText>
      <h4>{user?.displayName || user?.email.split('@')[0]}</h4>
      <span>{user?.email}</span>
      {user.removed && (
        <>
          <span className="removed">
            <strong style={{ color: theme.error }}>Removido por: </strong>
            {user?.removedByEmail || 'Usuário não encontrado'}
          </span>
          <span className="removed">
            <strong style={{ color: theme.error }}>Removido em: </strong>
            {user?.removedAt || 'Data não encontrada'}
          </span>
        </>
      )}
    </UserInfoText>
  );
  /* eslint-enable react/destructuring-assignment */

  UserInfo.propTypes = {
    user: PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
      removed: PropTypes.bool,
      removedByEmail: PropTypes.string,
      removedAt: PropTypes.string,
    }).isRequired,
  };

  const formatDate = (datetime) => (
    datetime ? formatDateTime(datetime, { time: 'none' }) : 'Não consta'
  );

  const formatRole = (role) => {
    let conditionalColor = 'error';
    let icon = <Question height="20px" />;
    let userType = 'Desconhecido';
    switch (role) {
      case 'super':
        conditionalColor = 'primary';
        icon = <UserConfig width="20px" />;
        userType = 'Proprietário';
        break;
      case 'scientist':
        conditionalColor = 'success';
        icon = <UserAtom width="20px" />;
        userType = 'Cientista';
        break;
      case 'guest':
        conditionalColor = 'warning';
        icon = <Users width="20px" />;
        userType = 'Convidado';
        break;
      default:
        break;
    }
    return (
      <Badge color={conditionalColor} style={{ margin: 'auto' }}>
        <BadgeContent>
          <IconWrapper color={conditionalColor}>
            {icon}
          </IconWrapper>
          {userType}
        </BadgeContent>
      </Badge>
    );
  };

  const columns = [
    {
      field: 'email',
      role: 'primaryInfo',
      label: 'Usuário',
      sortable: true,
      valueGetter: (param) => UserInfo(param),
    },
    {
      field: 'companyName',
      role: 'extraInfo',
      label: 'Cliente',
      sortable: true,
      valueGetter: (param) => param?.companyName,
    },
    {
      field: 'role',
      role: 'extraInfo',
      label: 'Permissão',
      sortable: true,
      valueGetter: (param) => formatRole(param?.role),
    },
    {
      field: 'created_at',
      role: 'extraInfo',
      label: 'Data da criação',
      sortable: true,
      valueGetter: (param) => formatDate(param?.created_at || ''),
    },
    {
      field: 'last_login',
      role: 'extraInfo',
      label: 'Último login',
      sortable: true,
      valueGetter: (param) => formatDate(param?.last_login || ''),
    },
    {
      field: 'options',
      role: 'optionsBtn',
      label: 'Opções',
      valueGetter: (param) => <UserOptions user={param} />,
    },
  ];

  return (
    <Table>
      <UsersTableOverFlow>
        <DataListingComponent
          columns={columns}
          rowData={data}
          strippedRowsColor={theme.containerOdd}
          defaultSortField="email"
          defaultSortOrder="ascending"
          headerColor="transparent"
          rowColor={theme.tableBackground}
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.background,
          }}
          usePagination
          page={page}
          itemsPerPage={20}
        />
      </UsersTableOverFlow>
      <TablePagination
        page={page}
        setPage={setPage}
        totalPages={Math.floor(data.length / 20)}
      />
    </Table>
  );
};

UsersTable.propTypes = {
  /**
   * Array with the data of the users
   */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
      company: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        operationalStatus: PropTypes.string,
      }),
      role: PropTypes.string,
      created_at: PropTypes.string,
      last_login: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Function to retrieve all the users of the project
   */
  getUsers: PropTypes.func,
  /**
   * Function to show the information of an specific user
   */
  getUserInfo: PropTypes.func,
  getUserHistory: PropTypes.func,
  getUserDashboards: PropTypes.func,
  filterText: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  handleReactivateUser: PropTypes.func,
  handleGetResetPwdLink: PropTypes.func,
  userIsAdmin: PropTypes.bool,
};

UsersTable.defaultProps = {
  getUsers: () => {},
  getUserInfo: () => {},
  getUserHistory: () => {},
  getUserDashboards: () => {},
  handleReactivateUser: () => {},
  filterText: '',
  userIsAdmin: false,
  handleGetResetPwdLink: () => {},
};

export default UsersTable;
