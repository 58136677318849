import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import { useTheme } from 'styled-components';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import IconButton from '../../../juristec-ui/core/IconButton';
import DatePicker from '../../../juristec-ui/core/DatePicker';

import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { compactString } from '../../../juristec-ui/utils/functions/formatString';
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import { Visibility, VisibilityOff } from '../../../juristec-ui/icons';

import { MainContainer, InputRow, BlockLabel } from './styled/PublicLinkCreate.styled';

const dateMidnight = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate());
};
const MINDATE = addDays(dateMidnight(), 1);

const PublicLinkCreate = ({
  isUpdate, permissions, submit, close,
}) => {
  const [password, setPassword] = useState({ value: '', error: false, errorMsg: '' });
  const [expirationDate, setExpirationDate] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [block, setBlock] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (permissions?.daysToExpire) {
      setExpirationDate(addDays(dateMidnight(), permissions.daysToExpire + 1));
    }
  }, [permissions?.daysToExpire]);

  const togglePass = () => setShowPass((p) => !p);

  const passwordHandle = (e) => {
    const val = compactString(e.target.value);
    const msg = verifyInput(val, false, 3);
    setPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = async () => {
    setBlock(true);
    const pwd = permissions.password ? password.value : null;
    const expDt = !permissions.daysToExpire ? (
      expirationDate
    ) : (
      addDays(dateMidnight(), permissions.daysToExpire + 1)
    );
    if (isUpdate) await submit(expDt);
    else await submit(pwd, expDt);
    setBlock(false);
  };

  const keyHandle = (e) => {
    if (e.key === 'Enter' && !(password.error || block)) handleSubmit();
  };

  return (
    <MainContainer>
      {!isUpdate && (
        <>
          {!permissions.password && (
            <BlockLabel>
              <strong style={{ color: theme.error }}>Atenção: </strong>
              O plano contratado não possui permissão de adicionar uma senha
              de acesso. O link será acessado por qualquer pessoa com acesso a URL
            </BlockLabel>
          )}
          <InputRow disabled={!permissions.password}>
            <InputTextLine
              label="Senha de acesso (opcional)"
              type={!showPass ? 'password' : 'text'}
              value={password.value}
              errorMessage={password.errorMsg}
              error={password.errorMsg.length > 0}
              onChange={passwordHandle}
              style={{ paddingRight: '35px' }}
              onKeyDown={keyHandle}
              autocomplete="new-password"
            />
            <IconButton
              style={{
                position: 'absolute',
                bottom: 4,
                right: 2,
                boxShadow: 'none',
                padding: 2,
              }}
              type="button"
              variant="pattern"
              iconColor="black"
              onMouseDown={togglePass}
            >
              {showPass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputRow>
        </>
      )}
      {!!permissions.daysToExpire && (
        <BlockLabel>
          <strong style={{ color: theme.error }}>Atenção: </strong>
          O plano contratado não possui permissão de alterar a data de expiração. O link expirará em
          <strong>
            {' '}
            {formatDateTime(expirationDate, { time: 'half' })}
          </strong>
        </BlockLabel>
      )}
      {!permissions.daysToExpire && (
        <BlockLabel>
          <strong style={{ color: theme.error }}>Atenção: </strong>
          Por questões de segurança, recomendamos que seja preenchida a
          data em que o link público será expirado
        </BlockLabel>
      )}
      <InputRow className="date-picker" disabled={!!permissions.daysToExpire}>
        <DatePicker
          value={expirationDate}
          onChange={setExpirationDate}
          label="Data de expiração (opcional)"
          minDate={MINDATE}
          dateFormat="dd/MM/yyyy"
          stayOpen={false}
          isClearable
          atModal
        />
      </InputRow>
      <ActionsGroup style={{ paddingTop: '0' }}>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={password.error || block}
        >
          {isUpdate ? 'Atualizar' : 'Gerar'}
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

PublicLinkCreate.propTypes = {
  isUpdate: PropTypes.bool,
  permissions: PropTypes.shape({
    daysToExpire: PropTypes.number,
    password: PropTypes.bool,
  }),
  close: PropTypes.func,
  submit: PropTypes.func.isRequired,
};

PublicLinkCreate.defaultProps = {
  isUpdate: false,
  permissions: {},
  close: () => {},
};

export default PublicLinkCreate;
