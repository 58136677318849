import React, { useState } from 'react';
import PropTypes from 'prop-types';

// styled
import {
  Container,
  TextContainer,
} from './styled/CopyText.styled';
import Tooltip from '../Tooltip';

const copyToClipboard = (copyText) => {
  navigator.clipboard.writeText(copyText).catch((err) => {
    console.error('Falha ao copiar: ', err);
  });
};

function CopyText({
  text,
}) {
  const [copied, setCopied] = useState(false);

  return (
    <Container>
      <Tooltip text={copied ? 'Copiado!' : 'Clique para copiar'} atModal>
        <TextContainer
          onClick={() => {
            copyToClipboard(text || '');
            setCopied(true);
          }}
          onMouseEnter={() => setCopied(false)}
        >
          {text}
        </TextContainer>
      </Tooltip>
    </Container>
  );
}

CopyText.propTypes = {
  /**
   * Copiable text.
  * */
  text: PropTypes.string.isRequired,
};

export default CopyText;
