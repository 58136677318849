/* eslint-disable react/forbid-prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import DataTable from '../../juristec-ui/core/DataTable/DataTable';
import TablePagination from '../../juristec-ui/core/TablePagination';
import Badge from '../../juristec-ui/core/Badge';
import Tooltip from '../../juristec-ui/core/Tooltip';
import IconButton from '../../juristec-ui/core/IconButton';
import Popover from '../../juristec-ui/core/Popover';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';

import { formatDateTime } from '../../juristec-ui/utils/functions/lab';

import {
  ClosedLock,
  Edit,
  ExpandMore,
  MoreVert,
  Trash,
  UserAtom,
  UserConfig,
  Users,
  AddUser,
  Report,
  Credentials,
  Antecipei,
  BennerSimpleGray,
  Message,
} from '../../juristec-ui/icons';

import {
  IconFlipWrapper,
  MainContainer,
  TableOverFlow,
  PlanLabel,
  CollapsibleContainer,
  ExtraInfos,
  ExtraItens,
  Options,
  Ged,
} from './styled/InstanceTable.styled';

/** Lists all instances and their options */
const InstanceTable = ({
  instances,
  handleBlock,
  handleEdit,
  handleDelete,
  handleAddUser,
  handleOnboarding,
  handleRequestApiCredentials,
  handleAddAntecipei,
  handleAddBenner,
  handleMessage,
  handleApiGedRegister,
  //
  openScientistsModal,
  openSupersModal,
  openGuestsModal,
  //
  userIsAdmin,
  plans,
  //
  filterText,
}) => {
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [showExtra, setShowExtra] = useState({});
  const [rowData, setRowData] = useState(instances);

  const handleExtraContent = (toggle, key) => {
    setShowExtra((old) => ({
      ...old,
      [key]: toggle,
    }));
  };

  const InstanceOptions = ({ instance }) => {
    const scientistModal = (e) => {
      e.stopPropagation();
      openScientistsModal(instance);
    };
    const supersModal = (e) => {
      e.stopPropagation();
      openSupersModal(instance);
    };
    const guestsModal = (e) => {
      e.stopPropagation();
      openGuestsModal(instance);
    };
    const addOnboardingReport = (e) => {
      e.stopPropagation();
      handleOnboarding(instance);
    };
    const requestL1extractorCredentials = (e) => {
      e.stopPropagation();
      handleRequestApiCredentials(instance);
    };

    return (
      <Options>
        <Tooltip text="Proprietários">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={supersModal}>
            <UserConfig />
          </IconButton>
        </Tooltip>
        <Tooltip text="Cientistas">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={scientistModal}>
            <UserAtom />
          </IconButton>
        </Tooltip>
        <>
          <Tooltip text="Convidados">
            <IconButton variant="pattern" shape="rounded" color="primary" onClick={guestsModal}>
              <Users />
            </IconButton>
          </Tooltip>
          {userIsAdmin && process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics' && (
            instance.l1extractor_credentials ? (
              <Tooltip text="Relatório de Onboarding">
                <IconButton variant="pattern" shape="rounded" color="primary" onClick={addOnboardingReport}>
                  <Report />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip text="Requisitar credenciais (Extrator)">
                <IconButton variant="pattern" shape="rounded" color="primary" onClick={requestL1extractorCredentials}>
                  <Credentials />
                </IconButton>
              </Tooltip>
            )
          )}
        </>
      </Options>
    );
  };

  const InstanceMoreOptions = ({ instance }) => {
    const [showOptions, setShowOptions] = useState(false);

    const blockInstance = (e) => {
      e.stopPropagation();
      handleBlock(instance);
    };

    const editInstace = (e) => {
      e.stopPropagation();
      handleEdit(instance);
    };

    const deleteInstance = (e) => {
      e.stopPropagation();
      handleDelete(instance);
    };

    const addUser = (e) => {
      e.stopPropagation();
      handleAddUser(instance);
    };

    const addAntecipei = (e) => {
      e.stopPropagation();
      handleAddAntecipei(instance);
    };

    const addBenner = (e) => {
      e.stopPropagation();
      handleAddBenner(instance);
    };

    const togglePopover = (e) => {
      e.stopPropagation();
      setShowOptions((o) => !o);
    };

    const message = (e) => {
      e.stopPropagation();
      handleMessage(instance);
    };

    const registerApiGed = (e) => {
      e.stopPropagation();
      handleApiGedRegister(instance, Boolean(instance?.apiGedCredentials));
    };

    return (
      <Popover closePopover={() => setShowOptions(false)} open={showOptions}>
        <Popover.Action>
          {(userIsAdmin || !instance.apiGedCredentials) && (
            <Tooltip text="Mais opções">
              <IconButton variant="pattern" shape="rounded" onClick={togglePopover}>
                <MoreVert aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
              </IconButton>
            </Tooltip>
          )}
        </Popover.Action>
        <Popover.Content>
          <List>
            {userIsAdmin && (
              <>
                <ListItem onClick={addUser}>
                  <AddUser />
                  Adicionar Usuário
                </ListItem>
                <ListItem onClick={addAntecipei}>
                  <Antecipei width="18px" height="18px" />
                  Criar Relatório Antecipei
                </ListItem>
                {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'benner-metrics' && (
                  <ListItem onClick={addBenner}>
                    <BennerSimpleGray width="18px" height="18px" style={{ fill: '#9a9a9a' }} />
                    Criar Relatório Benner
                  </ListItem>
                )}
              </>
            )}
            {(process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics'
              && (instance.operationalStatus !== 'Cancelado'
              && !instance.disabled)) && (
              <ListItem onClick={registerApiGed}>
                <Credentials width="18px" height="18px" />
                {instance.apiGedCredentials ? 'Atualizar credenciais API GED' : 'Registrar credenciais API GED'}
              </ListItem>
            )}
            {userIsAdmin && (
              <>
                <ListItem onClick={message}>
                  <Message />
                  Enviar Mensagem
                </ListItem>
                <ListItem onClick={blockInstance}>
                  <ClosedLock />
                  {instance.disabled ? 'Desbloquear Instância' : 'Bloquear Instância'}
                </ListItem>
                <ListItem onClick={editInstace}>
                  <Edit />
                  Editar Instância
                </ListItem>
                <ListItem onClick={deleteInstance}>
                  <Trash />
                  Apagar Instância
                </ListItem>
              </>
            )}
          </List>
        </Popover.Content>
      </Popover>
    );
  };

  InstanceMoreOptions.propTypes = {
    instance: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  InstanceOptions.propTypes = {
    instance: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  const getOperationalStatus = (param) => {
    let conditionalColor = 'transparent';
    switch (param.operationalStatus) {
      case 'Em implantação':
      case 'Em espera':
        conditionalColor = 'warning';
        break;
      case 'Suspenso':
      case 'Cancelado':
        conditionalColor = 'error';
        break;
      case 'Teste':
      case 'Comercial':
      case 'Prospecto':
        conditionalColor = 'info';
        break;
      case 'Preparado':
      case 'Operacional':
        conditionalColor = 'success';
        break;
      default:
        return (<>-</>);
    }
    return (
      <Badge color={conditionalColor} style={{ margin: 'auto' }}>
        {param.operationalStatus}
      </Badge>
    );
  };

  const checkGed = (hasGedCredentials) => {
    if (hasGedCredentials) {
      return (
        <Badge size="small" color={theme.info}>
          GED
        </Badge>
      );
    }
    return null;
  };

  const columns = useMemo(() => [
    {
      field: 'flipper',
      label: '',
      valueGetter: (param) => (
        <IconFlipWrapper flip={param.showMoreContent}>
          <ExpandMore />
        </IconFlipWrapper>
      ),
    },
    {
      field: 'name',
      label: `Instâncias (${instances.length})`,
      sortable: true,
      valueGetter: (param) => (
        <>
          <span>{param.name}</span>
          <Ged>
            {checkGed(param.apiGedCredentials)}
          </Ged>
        </>
      ),
    },
    {
      field: 'plan',
      label: 'Planos',
      sortable: true,
      valueGetter: (param) => (
        <PlanLabel>{plans[param.plan]?.label ?? ''}</PlanLabel>
      ),
    },
    {
      field: 'disabled',
      label: 'Status',
      sortable: true,
      valueGetter: (param) => (
        <Badge color={param.disabled ? 'error' : 'success'} style={{ margin: 'auto' }}>
          {param.disabled ? 'Inativo' : 'Ativo'}
        </Badge>
      ),
    },
    {
      field: 'operationalStatus',
      label: 'Situação',
      sortable: true,
      valueGetter: (param) => getOperationalStatus(param),
    },
    {
      field: 'created_at',
      label: 'Data de criação',
      sortable: true,
      valueGetter: (param) => (param.created_at ? formatDateTime(`${param.created_at}`) : '-'),
    },
    {
      field: 'options',
      label: 'Opções',
      valueGetter: (param) => <InstanceOptions instance={param} />,
    },
    {
      field: 'MoreOptions',
      label: '',
      valueGetter: (param) => <InstanceMoreOptions instance={param} />,
    },
  ], [instances]);

  const contentContainer = (open, extraData) => (
    <CollapsibleContainer control={open} maxSize={50}>
      <ExtraInfos>
        <ExtraItens>
          Memória:
          <Badge color="secondary" size="small">
            {extraData.volumeData || 0}
          </Badge>
        </ExtraItens>
        <ExtraItens>
          Snapshots:
          <Badge color="secondary" size="small">
            {extraData.snapshots || 0}
          </Badge>
        </ExtraItens>
        <ExtraItens>
          Super:
          <Badge color="secondary" size="small">
            {extraData.superS || 0}
          </Badge>
        </ExtraItens>
        <ExtraItens>
          Cientista:
          <Badge color="secondary" size="small">
            {extraData.scientists || 0}
          </Badge>
        </ExtraItens>
        <ExtraItens>
          Convidados:
          <Badge color="secondary" size="small">
            {extraData.guests || 0}
          </Badge>
        </ExtraItens>
      </ExtraInfos>
    </CollapsibleContainer>
  );

  useEffect(() => {
    setPage(0);
  }, [filterText]);

  useEffect(() => {
    setRowData(instances.reduce((aux, ins) => {
      if (filterText && !ins.name.toLowerCase().includes(filterText.toLocaleLowerCase())) {
        return aux;
      }
      aux.push({
        ...ins,
        moreContent: contentContainer(!!showExtra[ins.id], ins),
        showMoreContent: !!showExtra[ins.id],
        toggleMoreContent: (toggle) => handleExtraContent(toggle, ins.id),
      });
      return aux;
    }, []));
  }, [instances, showExtra, filterText]);

  return (
    <MainContainer>
      <TableOverFlow>
        <DataTable
          columns={columns}
          rowData={rowData}
          defaultSortField="name"
          defaultSortOrder="ascending"
          headerColor="transparent"
          rowColor={theme.tableBackground}
          strippedRowsColor={theme.containerOdd}
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: theme.background,
          }}
          expandRow
          usePagination
          page={page}
          itemsPerPage={20}
        />
      </TableOverFlow>
      <TablePagination
        page={page}
        setPage={setPage}
        totalPages={Math.floor(rowData.length / 20)}
      />
    </MainContainer>
  );
};

InstanceTable.propTypes = {
  /** Array of instances data */
  instances: PropTypes.arrayOf(PropTypes.shape({})),
  /** Function to block an instance */
  handleBlock: PropTypes.func,
  /** Function to edit an instance */
  handleEdit: PropTypes.func,
  /** Function to delete an instance */
  handleDelete: PropTypes.func,
  /** Function to add an user to instance */
  handleAddUser: PropTypes.func,
  /** Function to add an Antecipei report to the instance */
  handleAddAntecipei: PropTypes.func,
  /** Function to add a Benner report to the instance */
  handleAddBenner: PropTypes.func,
  /** Function to add the onboarding report to instance */
  handleOnboarding: PropTypes.func,
  /** Function to request the API credentials */
  handleRequestApiCredentials: PropTypes.func,
  /** Function to handle messages sent to users */
  handleMessage: PropTypes.func,
  /** Function to add the API GED Credentials of the company */
  handleApiGedRegister: PropTypes.func,
  /** Open a modal with the instance scientists */
  openScientistsModal: PropTypes.func,
  /** Open a modal with the instance supers */
  openSupersModal: PropTypes.func,
  /** Open a modal with the instance guests */
  openGuestsModal: PropTypes.func,
  /** String to filter instances by name */
  filterText: PropTypes.string,
  /** Checks if the user has admin role */
  userIsAdmin: PropTypes.bool,
  /** Contract plans */
  plans: PropTypes.objectOf(PropTypes.shape({})),
};

InstanceTable.defaultProps = {
  instances: [],
  handleBlock: () => {},
  handleEdit: () => {},
  handleDelete: () => {},
  handleAddUser: () => {},
  handleAddAntecipei: () => {},
  handleAddBenner: () => {},
  handleOnboarding: () => {},
  handleRequestApiCredentials: () => {},
  handleMessage: () => {},
  handleApiGedRegister: () => {},
  openScientistsModal: () => {},
  openSupersModal: () => {},
  openGuestsModal: () => {},
  filterText: '',
  userIsAdmin: false,
  plans: {},
};

export default InstanceTable;
