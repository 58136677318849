/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { Container, Options, UserInfos } from './styled/InstanceUserRow.styled';

import IconButton from '../IconButton';
import Avatar from '../Avatar';
import Tooltip from '../Tooltip';
import {
  ClosedLock,
  OpenLock,
  Edit,
  TransferUser,
  Trash,
  CloneDash,
  Sessions,
  ProfileUser,
  NewFile,
} from '../../icons';
import UsersReactivate from '../../icons/UserReactivate';

const InstanceUserRow = ({
  user,
  handleBlock,
  handleTransfer,
  handleDelete,
  handleReactivateUser,
  handlePermission,
  handleCopyDashboards,
  handleMultiSession,
  handleGenEmptyGed,
  userIsAdmin,
}) => {
  const theme = useTheme();
  const _handleBlock = () => {
    handleBlock(user);
  };

  const _handleTransfer = () => {
    handleTransfer(user);
  };

  const _handleDelete = () => {
    handleDelete(user);
  };

  const _handleReactivateUser = () => {
    handleReactivateUser(user);
  };

  const _handlePermission = () => {
    handlePermission(user);
  };

  const _handleCopyDashboards = () => {
    handleCopyDashboards(user);
  };

  const _lockMultiSession = () => {
    handleMultiSession(user, 'False');
  };

  const _unlockMultiSession = () => {
    handleMultiSession(user, 'True');
  };

  const _handleEmptyGed = () => {
    handleGenEmptyGed(user);
  };

  return (
    <Container>
      <UserInfos>
        <Avatar name={user.name} />
        <div className="instance-row-user-name-email">
          <span className="name">
            {user.name}
          </span>
          <span className="email">
            {user.email}
          </span>
          {user.removed && (
            <>
              <span className="removed">
                <strong style={{ color: theme.error }}>Removido por: </strong>
                <span>{user?.removedByEmail || 'Usuário não encontrado'}</span>
              </span>
              <span className="removed">
                <strong style={{ color: theme.error }}>Removido em: </strong>
                <span>{user?.removedAt || 'Data não encontrada'}</span>
              </span>
            </>
          )}
        </div>
      </UserInfos>
      <Options>
        {userIsAdmin && (
          <Tooltip text={user.disabled ? 'Desbloquear usuário' : 'Bloquear usuário'} atModal>
            {user.disabled
              ? (
                <IconButton variant="pattern" color="primary" onClick={_handleBlock} disabled={user?.removed}>
                  <OpenLock />
                </IconButton>
              ) : (
                <IconButton variant="pattern" color="primary" onClick={_handleBlock} disabled={user?.removed}>
                  <ClosedLock />
                </IconButton>
              )}
          </Tooltip>
        )}
        {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics' && user.role !== 'guest' && (
          <Tooltip text="Gerar relatório GED vazio" atModal>
            <IconButton variant="pattern" color="primary" onClick={_handleEmptyGed} disabled={user?.removed}>
              <NewFile />
            </IconButton>
          </Tooltip>
        )}
        {userIsAdmin && (
          <>
            {!!handleTransfer && (
              <>
                <Tooltip text="Transferir arquivos e copiar dashboards" atModal>
                  <IconButton variant="pattern" color="primary" onClick={_handleTransfer} disabled={user?.removed}>
                    <TransferUser />
                  </IconButton>
                </Tooltip>
                <Tooltip text="Verificar arquivos e dashboards" atModal>
                  <IconButton variant="pattern" color="primary" onClick={_handleCopyDashboards} disabled={user?.removed}>
                    <CloneDash />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip text="Alterar permissão" atModal>
              <IconButton variant="pattern" color="primary" onClick={_handlePermission} disabled={user?.removed}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip text={user.multiSessions ? 'Bloquear sessão simultânea' : 'Liberar sessão simultânea'} atModal>
              {user.multiSessions
                ? (
                  <IconButton variant="pattern" color="primary" onClick={_lockMultiSession} disabled={user?.removed}>
                    <ProfileUser />
                  </IconButton>
                ) : (
                  <IconButton variant="pattern" color="primary" onClick={_unlockMultiSession} disabled={user?.removed}>
                    <Sessions />
                  </IconButton>
                )}
            </Tooltip>
            {user.removed ? (
              <Tooltip text="Reativar usuário" atModal>
                <IconButton variant="pattern" color="primary" onClick={_handleReactivateUser}>
                  <UsersReactivate />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip text="Apagar usuário" atModal>
                <IconButton variant="pattern" color="primary" onClick={_handleDelete}>
                  <Trash />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Options>
    </Container>
  );
};

InstanceUserRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    disabled: PropTypes.bool,
    multiSessions: PropTypes.bool,
    role: PropTypes.string,
    removed: PropTypes.bool,
    removedByEmail: PropTypes.string,
    removedAt: PropTypes.string,
  }),
  handleTransfer: PropTypes.func,
  handleBlock: PropTypes.func,
  handleDelete: PropTypes.func,
  handleReactivateUser: PropTypes.func,
  handlePermission: PropTypes.func,
  handleCopyDashboards: PropTypes.func,
  handleMultiSession: PropTypes.func,
  handleGenEmptyGed: PropTypes.func,
  userIsAdmin: PropTypes.bool,
};

InstanceUserRow.defaultProps = {
  user: {},
  handleBlock: () => {},
  handleTransfer: undefined,
  handleDelete: () => {},
  handleReactivateUser: () => {},
  handlePermission: () => {},
  handleCopyDashboards: () => {},
  handleMultiSession: () => {},
  handleGenEmptyGed: () => {},
  userIsAdmin: false,
};

export default InstanceUserRow;
